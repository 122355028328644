<template>
  <not-found v-if="song === null"/>
  <div class="lg:flex lg:space-x-10" v-if="song">
    <div class="lg:w-3/5 space-y-7 mb-8">
      <div class="card">
        <player v-if="type === 'video'" :options="playerOptions" type="video" :key="song.id"/>
        <img v-if="type === 'audio'" :alt="song.title"
             :src="image"
             class="object-cover w-full"/>

        <div class=" mt-2 pr-2 text-right">
          <a :href="url + '?type=video'" v-if="type === 'audio' && song.file_type === 'video'" class="float-left mx-3 px-2 py-1 rounded-md border border-gray-300 font-medium text-white hover:text-white hover:bg-green-700 bg-green-600"><i class="uil uil-video"></i> Phiên bản video </a>
          <a :href="url.replace('?type=video', '')" v-if="type === 'video'" class="px-2 py-1 rounded-md border border-gray-300 font-medium text-white hover:text-white hover:bg-green-700 bg-green-600"><i class="uil uil-music"></i> Phiên bản audio </a>
          <div v-if="song.thumbnail && song.thumbnail.user && type === 'audio'">
            Ảnh minh họa:
            <router-link class="font-semibold" :to="{name: 'UserDetail', params: {id: song.thumbnail.user.id}}"
                        :title="song.thumbnail.user.username">
              {{ song.thumbnail.user.username }}
            </router-link>
          </div>
        </div>

        <div class="p-3">
          <h1 class="text-2xl font-semibold">{{ song.title }} <span class="text-gray-500"
                                                                    v-if="song.subtitle">{{ song.subtitle }}</span>
          </h1>
          <div class="flex justify-between">
            <div>
              <p v-if="song.fcats && song.fcats.data && song.fcats.data.length && (prefix === 'dan-ca' || prefix === 'thanh-vien-hat')"
                  class="people-in-song">
                Thể loại:
                <fcat-of-song :fcats="song.fcats.data"/>
              </p>
              <p v-if="song.melodies && song.melodies.data && song.melodies.data.length && (prefix === 'dan-ca' || prefix === 'thanh-vien-hat')"
                  class="people-in-song">
                Làn điệu:
                <melody-of-song :melodies="song.melodies.data"/>
              </p>
              <p v-if="song.sheet && song.sheet.composers && song.sheet.composers.data && song.sheet.composers.data.length">
                Sáng tác:
                <span v-if="song.sheet.composer_alias" class="font-semibold">{{ song.sheet.composer_alias }} (</span>
                <span class="people-in-song">
                  <people-of-song :people="song.sheet.composers.data" type="composer"
                                  class="font-semibold"/>
                </span>
                <template v-if="song.sheet.composer_alias">)</template>
                <span class="text-gray-500" v-if="song.sheet && song.sheet.year"> ({{ song.sheet.year }})</span>
              </p>
              <p v-if="song.poets && song.poets.data && prefix === 'tieng-tho'">
                <span v-if="song.sheet">Thơ</span>
                <span v-else>Sáng tác</span>
                <span class="people-in-song">
                  : <people-of-song :people="song.poets.data" type="poet" class="font-semibold"/>
                </span>
                <span class="text-gray-500" v-if="song.year"> ({{ song.year }})</span>
              </p>
              <p v-if="song.recomposers && song.recomposers.data && song.recomposers.data.length && prefix === 'dan-ca'"
                  class="people-in-song">
                Soạn lời:
                <people-of-song :people="song.recomposers.data" type="recomposer" class="font-semibold"/>
              </p>
              <p v-if="song.sheet && song.sheet.poets && song.sheet.poets.data && song.sheet.poets.data.length && prefix === 'bai-hat'"
                  class="people-in-song">
                {{ song.sheet.lyric_type ? song.sheet.lyric_type : 'Thơ: ' }}{{ song.sheet.lyric_type && song.sheet.lyric_type.indexOf(':') === -1 ? ": " : " " }}
                <people-of-song :people="song.sheet.poets.data" type="poet" class="font-semibold"/>
              </p>
              <p v-if="song.artists && song.artists.data">
                Trình bày:
                <span class="people-in-song">
                  <people-of-song :people="song.artists.data" type="artist"
                                  class="font-semibold"/>
                </span>
                <span class="text-gray-500" v-if="song.record_year"> ({{ song.record_year }})</span>
              </p>
              <p v-if="song.users && song.users.data">
                Trình bày:
                <span class="people-in-song">
                  <users-of-song :people="song.users.data" class="font-semibold"/>
                </span>
                <span class="text-gray-500" v-if="song.record_year"> ({{ song.record_year }})</span>
              </p>
              <p v-if="song.uploader" class="people-in-song">
                Đóng góp:
                <user-of-song :people="[song.uploader]" class="font-semibold"/>
              </p>
              <div v-if="song.file && song.file.user" class="flex items-center">
                <div>
                  Bản thu sử dụng:
                  <user-of-song :people="[song.file.user]" class="font-semibold"/>
                </div>
                <div>
                  <a href="#" uk-toggle="target: #replaceHistoryModal" v-if="song.uploads && song.uploads.length > 1" aria-haspopup="true" aria-expanded="false"><i class="icon-feather-more-horizontal text-2xl hover:bg-gray-200 rounded-full p-2 transition -mr-1 dark:hover:bg-gray-700"></i></a>
                </div>
              </div>
              <p>Ngày đăng: <span class="font-semibold">{{ convertDate(song.created_at) }}</span></p>
            </div>

            <div class="flex flex-col items-center justify-center">
              <a
                href="javascript:void(0)"
                v-on:click="loveOrUnlove()"
                :uk-tooltip="'title: ' + getUserLoves()"
                class="text-red-600 mr-1"
              >
                <i class="far fa-heart text-3xl" v-if="!hasLoved"></i>
                <i
                  class="fas fa-heart text-red-600 text-3xl"
                  v-if="hasLoved"
                ></i>
              </a>
              <span v-if="song.likes">{{ song.likes }}</span>
              <span uk-tooltip="title: Bản thu VOV chất lượng cao" v-if="song.file && song.file.is_hq == 1" style="background: rgb(113, 19, 19); border-radius:3px" class="mt-3 whitespace-nowrap px-1 text-white"><small>vov-hq</small></span>
              <span class="mt-3 whitespace-nowrap px-1 text-white" style="background: rgb(181 181 181);border-radius: 3px;"><small>{{ song.file.bitrate ? song.file.bitrate : "128" }} kbps</small></span>
            </div>
          </div>
          
          <tag class="mt-3" :tags="song.sheet.tags" v-if="song.sheet && song.sheet.tags && song.sheet.tags.length"/>
        </div>

        <div v-if="canMarkHq || canManageSong || canManageSheet || canUpdateThumbnail || canManageThumbnail || canUpdateLyric" class="float-right">
          <a href="#">
            <i
              class="icon-feather-more-horizontal text-2xl hover:bg-gray-200 rounded-full p-2 transition -mr-1 dark:hover:bg-gray-700"
            ></i>
          </a>
          <div
            class="bg-white w-62 shadow-md mx-auto p-2 mt-12 rounded-md text-gray-500 hidden text-base border border-gray-100 dark:bg-gray-900 dark:text-gray-100 dark:border-gray-700"
            uk-drop="mode: click;pos: bottom-right;animation: uk-animation-slide-bottom-small"
          >
            <ul class="space-y-1">
              <li v-if="canManageSheet && song.sheet"><a class="uk-drop-close flex items-center px-3 py-2 hover:bg-gray-200 hover:text-gray-800 rounded-md dark:hover:bg-gray-800" href="#" uk-toggle="target: #updateSongYearModal"><i class="uil-pen mr-1"></i> Sửa năm sáng tác</a></li>
              <li v-if="canUpdateLyric"><a class="uk-drop-close flex items-center px-3 py-2 hover:bg-gray-200 hover:text-gray-800 rounded-md dark:hover:bg-gray-800" href="javascript:void(0)" v-on:click="focusOnEditor"><i class="uil-pen mr-1"></i> Sửa lời</a></li>
              <li v-if="canManageSheet && song.sheet"><a class="uk-drop-close flex items-center px-3 py-2 hover:bg-gray-200 hover:text-gray-800 rounded-md dark:hover:bg-gray-800" href="#" uk-toggle="target: #updateSheetTagModal"><i class="uil-tag mr-1"></i> Sửa tag</a></li>
              <li v-if="canUpdateThumbnail && (isAdmin || canManageThumbnail || !song.thumbnail || (song.thumbnail && (!song.thumbnail.user || (song.thumbnail.user && isOwner(song.thumbnail.user.id)))))"><a class="uk-drop-close flex items-center px-3 py-2 hover:bg-gray-200 hover:text-gray-800 rounded-md dark:hover:bg-gray-800" href="#" uk-toggle="target: #updateImageModal"><i class="uil-plus mr-1"></i> Ảnh minh họa</a></li>
              <!-- <li v-if="canUpdateThumbnail"><a class="uk-drop-close flex items-center px-3 py-2 hover:bg-gray-200 hover:text-gray-800 rounded-md dark:hover:bg-gray-800" href="#" uk-toggle="target: #updateImageModal"><i class="uil-plus mr-1"></i> Ảnh minh họa</a></li> -->
              <li v-if="canManageSheet && song.sheet && song.sheet.id">
                <router-link
                    class="uk-drop-close flex items-center px-3 py-2 hover:bg-gray-200 hover:text-gray-800 rounded-md dark:hover:bg-gray-800"
                    :to="{name: 'AdminSheetEdit', params: {id: this.song.sheet.id}}">
                  <i class="uil-pen mr-1"></i> Sửa bản nhạc
                </router-link>
              </li>
              <li v-if="canMarkHq">
                <div class="flex px-3 py-2 hover:bg-gray-200 hover:text-gray-800 rounded-md dark:hover:bg-gray-800">
                  <label class="switch flex items-center mb-4">
                    <input type="checkbox" v-model="song.is_hq" v-on:change="markOrUnmarkAsHQ">
                    <span class="switch-button"></span>
                  </label>
                  <span class="ml-10">Bản thu VOV chất lượng cao</span>
                </div>
              </li>
              <li v-if="canManageSong">
                <router-link :to="{name: editRouter, params: {id: song.id}}"
                  class="uk-drop-close flex items-center px-3 py-2 hover:bg-gray-200 hover:text-gray-800 rounded-md dark:hover:bg-gray-800">
                  <i class="uil-pen mr-1"></i> Sửa
                </router-link>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <update-image-modal title="Cập nhật ảnh minh họa" v-on:updateImageDone="loadSong" update-type="updateThumbnail"
                          :type="objectType" :id="song.id"/>
      <update-song-year-modal v-on:updateSongYearDone="loadSong" :year="song.sheet && song.sheet.year ? song.sheet.year : ''" :sheet_id="song.sheet ? song.sheet.id : ''"/>
      <update-tag-modal v-on:updateDone="handleUpdateSheetTagDone" :tags="song.sheet && song.sheet.tags ? song.sheet.tags : []" title="Cập nhật tag cho bài hát"/>
      <replace-history-modal v-if="song.uploads && song.uploads.length" :uploads="song.uploads" :file_id="song.file.id"/>

      <router-link style="background:#711313" class="text-white mt-3 flex justify-content-center px-3 py-2 rounded-md items-center" v-if="song.empty_file == 1" :to="{name: 'UploadAdd', params: {prefix: 'thay-the-ban-thu'}, query: {url: url}}">
        <div class="mr-3">
            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32"><path fill="currentColor" d="M4.834 4.834c-5.89 5.89-5.89 15.442 0 21.333s15.44 5.888 21.33-.002c5.89-5.89 5.893-15.44.002-21.33c-5.89-5.89-15.44-5.89-21.332 0zm20.625.708a14.164 14.164 0 0 1 2.103 2.726l-4.08 4.08A8.528 8.528 0 0 0 21.57 9.43a8.52 8.52 0 0 0-2.92-1.913l4.08-4.08a14.15 14.15 0 0 1 2.73 2.105zm-15.32 15.32a7.588 7.588 0 0 1-.002-10.725a7.592 7.592 0 0 1 10.725 0a7.595 7.595 0 0 1 0 10.724a7.59 7.59 0 0 1-10.724.002zM5.54 25.46a14.19 14.19 0 0 1-2.105-2.73l4.08-4.08a8.583 8.583 0 0 0 4.832 4.832l-4.082 4.08c-.97-.58-1.89-1.27-2.726-2.103zM8.268 3.434l4.082 4.082a8.558 8.558 0 0 0-4.832 4.831l-4.082-4.08c.58-.97 1.27-1.89 2.105-2.728a14.209 14.209 0 0 1 2.728-2.105zm14.464 24.128L18.65 23.48a8.549 8.549 0 0 0 4.832-4.83l4.082 4.08c-.58.97-1.27 1.892-2.105 2.73a14.197 14.197 0 0 1-2.728 2.103z"/></svg>
        </div>
        <div class="">
          <p>Bản thu đang bị mất dữ liệu</p>
          <p>Nhờ bạn khôi phục file nhạc</p>
        </div>
      </router-link>

      
      <div class="mt-3" v-if="song.empty_file == 0">
        <div class="flex justify-content-between">
          <div class="text-center">
            <div class="flex flex-col items-center" uk-tooltip="title: Thêm vào playlist">
              <a class="mb-2 px-4 py-1 rounded-md border border-gray-300 font-medium text-white hover:text-white hover:bg-green-700 bg-green-600"
                 href="#" id="addToPlaylistBtn" uk-toggle="target: #add-to-playlist-modal">
                <svg fill="currentColor" viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet" focusable="false" class="w-6 h-6">
                  <g class="style-scope yt-icon">
                    <path
                        d="M22,13h-4v4h-2v-4h-4v-2h4V7h2v4h4V13z M14,7H2v1h12V7z M2,12h8v-1H2V12z M2,16h8v-1H2V16z"></path>
                  </g>
                </svg>
              </a>
              <span>+ Playlist</span>
            </div>
          </div>
          <div class="text-center">
            <div
              class="mb-2 px-4 py-1 rounded-md border border-gray-300 font-medium text-white"
              style="background-color: #1877f2"
              >
              <i class="uil uil-facebook-f"></i>
              <ShareNetwork
                network="facebook"
                class="text-white"
                :url="url"
                :title="title"
              >
              Chia sẻ
              </ShareNetwork>
            </div>
            
          </div>
          <div class="text-center">
            <div class="flex flex-col items-center" :uk-tooltip="'title: ' + thousandFormat(song.views) + ' lượt nghe'">
              <span
                  class="mb-2 px-4 py-1 rounded-md border border-gray-300 font-medium text-white hover:bg-green-700 bg-green-600"><i
                  class="uil uil-headphones"></i></span>
              <span>{{ numberFormat(song.views) }}</span>
            </div>
          </div>
          <div class="text-center">
            <div class="flex flex-col items-center"
                 :uk-tooltip="'title: ' + thousandFormat(song.downloads) + ' lượt tải'">
              <a class="mb-2 px-4 py-1 rounded-md border border-gray-300 font-medium text-white hover:text-white hover:bg-green-700 bg-green-600"
                 href="javascript:void(0)" v-on:click="downloadSong">
                <i class="uil uil-download-alt"></i> Tải về
              </a>
              <span>{{ numberFormat(song.downloads) }}</span>
            </div>
          </div>
        </div>

        <add-to-playlist :object_id="parseInt(id)" :object_type="objectType" :key="addToPlaylistKey"
                         :playlists="user.playlists && user.playlists.data ? user.playlists.data : []" v-if="user"/>
      </div>

      <div class="mt-4" v-if="sheetImages && sheetImages.length">
        <div class="lg:px-20">
          <div class="relative lg:-mx-20" uk-slider="finite: true">
            <div class="uk-slider-container">
              <ul :class="'uk-slider-items uk-child-width-1-3@m uk-child-width-1-3@s uk-child-width-1-3 uk-grid-small uk-grid'">
                <li v-for="(image, index) in sheetImages" :key="'sheet' + index">
                  <div class="card">
                    <a @click="openSheetLightbox(index)" class="sheet-lightbox" href="javascript:void(0)">
                      <img :src="image.source" alt="" class="object-cover w-full h-44">
                    </a>
                    <router-link class="text-center line-clamp-1 p-1" :title="'Xem chi tiết bản nhạc ' + image.sheet.title" :to="{name: 'SheetDetail', params: {id: image.sheet.id, slug: image.sheet.slug}}">
                      <h3 class="text-underline">{{image.caption}}</h3>
                    </router-link>
                  </div>
                </li>
              </ul>

              <a class="absolute bg-white bottom-1/2 flex items-center justify-center p-2 left-2 rounded-full shadow-md text-xl w-9 z-10 dark:bg-gray-800 dark:text-white"
                href="#" uk-slider-item="previous"> <i class="icon-feather-chevron-left"></i></a>
              <a class="absolute bg-white bottom-1/2 flex items-center justify-center p-2 right-2 rounded-full shadow-md text-xl w-9 z-10 dark:bg-gray-800 dark:text-white"
                href="#" uk-slider-item="next"> <i class="icon-feather-chevron-right"></i></a>
            </div>
          </div>
        </div>
      </div>

      <div class="card p-4 mt-4">
        <div v-if="!isEditing">
          <show-more :show="true" :content="song.content" :length="200" v-if="song.content" class="space-y-3"></show-more>
          <p v-if="!song.content">Bài hát này chưa được chép lời. Mời bạn đóng góp lời bài hát!</p>
          <div class="" v-if="updateLyricUsers && updateLyricUsers.length">
            <p class="font-semibold">Đóng góp lời:</p>
            <div class="flex items-center">
              <router-link :uk-tooltip="'title: ' + user.username" v-for="(user,index) in updateLyricUsers"
                           v-bind:class="{'-ml-2': index > 0 }"
                           :key="'updateLyricUsers' + index" :to="{name: 'UserDetail', params: {id: user.id}}"
                           :title="user.username">
                <img
                    :alt="user.username"
                    :src="user && user.avatar ? user.avatar.url : 'https://static.bcdcnt.net/assets/images/avatars/avatar-2.jpg'"
                    class="w-10 h-10 rounded-full border-2 border-white dark:border-gray-900">
              </router-link>
            </div>
          </div>
          <p class="float-right mt-2">
            <button uk-toggle="target: #lyricHistoryModal" v-if="updateLyrics.length"
                    class="comment-btn mr-2"
                    style="background-color: rgb(24, 119, 242);">
              <i class="uil-eye mr-1"></i>Lịch sử
            </button>
            <button v-on:click="focusOnEditor" v-if="canUpdateLyric"
                    class="comment-btn hover:bg-green-700">
              <i class="uil-pen mr-1"></i>Sửa lời
            </button>
          </p>
        </div>

        <div v-if="isEditing">
          <editor id="lyric" v-model:content="newContent"/>
          <div class="flex justify-content-end">
            <button class="cancel-comment-btn mt-2 mr-2" v-on:click="isEditing = false">
              Hủy
            </button>
            <button class="comment-btn mt-2 hover:bg-green-700" v-on:click="updateLyric" :disabled="disableLyricBtn">Cập
              nhật
            </button>
          </div>
        </div>
      </div>

      <div class="mt-4">
        <comment :object-type="objectType" :allow-comment="song.allow_comment !== 0" :comment-id="commentId"
                 :object-id="id" field="id" type="ID" :show-create-sheet="true" v-on:createSheetFromCommentDone="handleCreateSheetFromCommentDone" v-on:replaceFileFromCommentDone="handleReplaceFileFromCommentDone" v-on:replaceImageFromCommentDone="handleReplaceImageFromCommentDone"/>
      </div>

      <div class="card p-3">
        <latest-song-box/>
      </div>
    </div>

    <div class="lg:w-2/5 w-full space-y-7">
      <sticky-people/>
      
      <div id="same-songs" v-if="sameSongs.paginatorInfo.total > 1" class="card p-3">
        <div class="flex align-items-center mb-2 justify-content-between">
          <h3 class="text-xl font-semibold line-clamp-1">
            Các bản thu khác của bài này ({{ sameSongs.paginatorInfo.total }})
          </h3>
          <div v-if="sameSongs.paginatorInfo.lastPage > 1">
            <next-prev
                class="flex justify-end"
                v-on:clickPrev="sameSongs.paginatorInfo.currentPage = sameSongs.paginatorInfo.currentPage > 1 ? sameSongs.paginatorInfo.currentPage - 1 : sameSongs.paginatorInfo.lastPage; loadSameSongs()"
                v-on:clickNext="sameSongs.paginatorInfo.currentPage = sameSongs.paginatorInfo.currentPage < sameSongs.paginatorInfo.lastPage ? sameSongs.paginatorInfo.currentPage + 1 : 1; loadSameSongs()"
            />
          </div>
        </div>
        <div class="same-songs">
          <song-item v-for="song in sameSongs.data" :current-id="id" :song="song" :prefix="prefix"
                     :key="'sameSongs' + song.id"/>
        </div>
      </div>

      <div class="card p-3" v-if="suggestSongs.length > 0">
        <div id="suggest-songs" class="mb-4">
          <h3 class="text-xl font-semibold mb-2 md:flex items-center justify-between">
            Có thể bạn muốn nghe
          </h3>
          <div class="same-songs">
            <song-item v-for="song in suggestSongs" :song="song" :prefix="prefix" :key="'suggestSongs' + song.id"/>
          </div>
        </div>
      </div>

      <div id="same-songs-song" v-if="sameSongsSong.paginatorInfo.total > 0" class="card p-3">
        <div class="flex align-items-center mb-2 justify-content-between">
          <h3 class="text-xl font-semibold line-clamp-1">
            Nghệ sĩ hát bài này ({{ sameSongsSong.paginatorInfo.total }})
          </h3>
          <div v-if="sameSongsSong.paginatorInfo.lastPage > 1">
            <next-prev
                class="flex justify-end"
                v-on:clickPrev="sameSongsSong.paginatorInfo.currentPage = sameSongsSong.paginatorInfo.currentPage > 1 ? sameSongsSong.paginatorInfo.currentPage - 1 : sameSongsSong.paginatorInfo.lastPage; loadSameSongs('song')"
                v-on:clickNext="sameSongsSong.paginatorInfo.currentPage = sameSongsSong.paginatorInfo.currentPage < sameSongsSong.paginatorInfo.lastPage ? sameSongsSong.paginatorInfo.currentPage + 1 : 1; loadSameSongs('song')"
            />
          </div>
        </div>
        <div class="same-songs">
          <song-item v-for="song in sameSongsSong.data" :song="song" prefix="bai-hat" :key="'sameSongsSong' + song.id"/>
        </div>
      </div>

      <div id="same-songs-instrumental" v-if="sameSongsInstrumental.paginatorInfo.total > 0" class="card p-3">
        <div class="flex align-items-center mb-2 justify-content-between">
          <h3 class="text-xl font-semibold line-clamp-1">
            Nhạc không lời ({{ sameSongsInstrumental.paginatorInfo.total }})
          </h3>
          <div v-if="sameSongsInstrumental.paginatorInfo.lastPage > 1">
            <next-prev
                class="flex justify-end"
                v-on:clickPrev="sameSongsInstrumental.paginatorInfo.currentPage = sameSongsInstrumental.paginatorInfo.currentPage > 1 ? sameSongsInstrumental.paginatorInfo.currentPage - 1 : sameSongsInstrumental.paginatorInfo.lastPage; loadSameSongs('instrumental')"
                v-on:clickNext="sameSongsInstrumental.paginatorInfo.currentPage = sameSongsInstrumental.paginatorInfo.currentPage < sameSongsInstrumental.paginatorInfo.lastPage ? sameSongsInstrumental.paginatorInfo.currentPage + 1 : 1; loadSameSongs('instrumental')"
            />
          </div>
        </div>
        <div class="same-songs">
          <song-item v-for="song in sameSongsInstrumental.data" :song="song" prefix="khi-nhac"
                     :key="'sameSongsInstrumental' + song.id"/>
        </div>
      </div>

      <div id="same-songs-karaoke" v-if="sameSongsKaraoke.paginatorInfo.total > 0" class="card p-3">
        <div class="flex align-items-center mb-2 justify-content-between">
          <h3 class="text-xl font-semibold line-clamp-1">
            Thành viên hát bài này ({{ sameSongsKaraoke.paginatorInfo.total }})
          </h3>
          <div v-if="sameSongsKaraoke.paginatorInfo.lastPage > 1">
            <next-prev
                class="flex justify-end"
                v-on:clickPrev="sameSongsKaraoke.paginatorInfo.currentPage = sameSongsKaraoke.paginatorInfo.currentPage > 1 ? sameSongsKaraoke.paginatorInfo.currentPage - 1 : sameSongsKaraoke.paginatorInfo.lastPage; loadSameSongs('karaoke')"
                v-on:clickNext="sameSongsKaraoke.paginatorInfo.currentPage = sameSongsKaraoke.paginatorInfo.currentPage < sameSongsKaraoke.paginatorInfo.lastPage ? sameSongsKaraoke.paginatorInfo.currentPage + 1 : 1; loadSameSongs('karaoke')"
            />
          </div>
        </div>
        <div class="same-songs">
          <song-item v-for="song in sameSongsKaraoke.data" :song="song" prefix="thanh-vien-hat"
                     :key="'sameSongsKaraoke' + song.id"/>
        </div>
      </div>

      <div id="artist-songs" class="space-y-7">
        <div v-for="(item, id) in artistSongs" :key="'artist-songs' + id">
          <div class="card p-3">
            <div class="flex align-items-center mb-2 justify-between">
              <h3 class="text-xl font-semibold line-clamp-1">
                Do {{ item.title }} trình bày
              </h3>
              <div v-if="artistSongs[id].paginatorInfo.lastPage > 1">
                <next-prev
                    class="flex justify-end"
                    v-on:clickPrev="artistSongs[id].paginatorInfo.currentPage = artistSongs[id].paginatorInfo.currentPage > 1 ? artistSongs[id].paginatorInfo.currentPage - 1 : artistSongs[id].paginatorInfo.lastPage; loadArtistSongs(id)"
                    v-on:clickNext="artistSongs[id].paginatorInfo.currentPage = artistSongs[id].paginatorInfo.currentPage < artistSongs[id].paginatorInfo.lastPage ? artistSongs[id].paginatorInfo.currentPage + 1 : 1; loadArtistSongs(id)"
                />
              </div>
            </div>

            <div class="same-songs">
              <song-item v-for="song in item.data" :song="song" :prefix="prefix" :key="'artist-song' + song.id"/>
            </div>
          </div>
        </div>
      </div>

      <div id="user-songs" class="space-y-7">
        <div v-for="(item, id) in userSongs" :key="'user-songs' + id">
          <div class="card p-3">
            <div class="flex align-items-center mb-2 justify-content-between">
              <h3 class="text-xl font-semibold line-clamp-1">
                Do {{ item.title }} trình bày
              </h3>
              <div v-if="userSongs[id].paginatorInfo.lastPage > 1">
                <next-prev
                    class="flex justify-end"
                    v-on:clickPrev="userSongs[id].paginatorInfo.currentPage = userSongs[id].paginatorInfo.currentPage > 1 ? userSongs[id].paginatorInfo.currentPage - 1 : userSongs[id].paginatorInfo.lastPage; loadUserSongs(id)"
                    v-on:clickNext="userSongs[id].paginatorInfo.currentPage = userSongs[id].paginatorInfo.currentPage < userSongs[id].paginatorInfo.lastPage ? userSongs[id].paginatorInfo.currentPage + 1 : 1; loadUserSongs(id)"
                />
              </div>
            </div>

            <div class="same-songs">
              <song-item v-for="song in item.data" :song="song" :prefix="prefix" :key="'user-song' + song.id"/>
            </div>
          </div>
        </div>
      </div>

      <related-document-box :documents="song.documents.data"
                            v-if="song.documents && song.documents.data && song.documents.data.length"/>

      <div id="composer-songs" class="space-y-7">
        <div v-for="(item, id) in composerSongs" :key="'composer-songs' + id">
          <div class="card p-3">
            <div class="flex align-items-center mb-2 justify-content-between">
              <h3 class="text-xl font-semibold line-clamp-1">
                Do {{ item.title }} sáng tác
              </h3>
              <div v-if="composerSongs[id].paginatorInfo.lastPage > 1">
                <next-prev
                    class="flex justify-end"
                    v-on:clickPrev="composerSongs[id].paginatorInfo.currentPage = composerSongs[id].paginatorInfo.currentPage > 1 ? composerSongs[id].paginatorInfo.currentPage - 1 : composerSongs[id].paginatorInfo.lastPage; loadComposerSongs(id)"
                    v-on:clickNext="composerSongs[id].paginatorInfo.currentPage = composerSongs[id].paginatorInfo.currentPage < composerSongs[id].paginatorInfo.lastPage ? composerSongs[id].paginatorInfo.currentPage + 1 : 1; loadComposerSongs(id)"
                />
              </div>
            </div>
            <div class="same-songs">
              <song-item v-for="song in item.data" :song="song" :prefix="prefix" :key="'composer-song' + song.id"/>
            </div>
          </div>
        </div>
      </div>

      <div id="poet-songs" class="space-y-7">
        <div v-for="(item, id) in poetSongs" :key="'poet-songs' + id">
          <div class="card p-3">
            <div class="flex align-items-center mb-2 justify-content-between">
              <h3 class="text-xl font-semibold line-clamp-1">
                {{ prefix === 'tieng-tho' ? 'Do ' + item.title + ' sáng tác' : 'Lời của ' + item.title }}
              </h3>
              <div v-if="poetSongs[id].paginatorInfo.lastPage > 1">
                <next-prev
                    class="flex justify-end"
                    v-on:clickPrev="poetSongs[id].paginatorInfo.currentPage = poetSongs[id].paginatorInfo.currentPage > 1 ? poetSongs[id].paginatorInfo.currentPage - 1 : poetSongs[id].paginatorInfo.lastPage; loadPoetSongs(id)"
                    v-on:clickNext="poetSongs[id].paginatorInfo.currentPage = poetSongs[id].paginatorInfo.currentPage < poetSongs[id].paginatorInfo.lastPage ? poetSongs[id].paginatorInfo.currentPage + 1 : 1; loadPoetSongs(id)"
                />
              </div>
            </div>

            <div class="same-songs">
              <song-item v-for="song in item.data" :song="song" :prefix="prefix" :key="'poet-song' + song.id"/>
            </div>
          </div>
        </div>
      </div>

      <div class="card p-3">
        <latest-comment/>
      </div>

      <div class="card p-3">
        <statistic-listen/>
      </div>

      <right/>
    </div>

    <div id="loginModal" class="create-post is-story" uk-modal>
      <div
          class="open uk-modal-dialog uk-modal-body uk-margin-auto-vertical rounded-lg p-0 lg:w-5/12 relative shadow-2xl uk-animation-slide-bottom-small">

        <div class="text-center py-3 border-b">
          <h3 class="text-lg font-semibold">Đăng nhập</h3>
          <button class="uk-modal-close-default bg-gray-100 rounded-full p-2.5 right-2" type="button" uk-close uk-tooltip="title: Close ; pos: bottom ;offset:7"></button>
        </div>

        <login-authenticate :show-title="false" :show-register="false" :show-forgot="false"/>
      </div>
    </div>

    <div id="lyricHistoryModal" class="create-post" uk-modal>
      <div
          class="open uk-modal-dialog uk-modal-body uk-margin-auto-vertical rounded-lg p-0 lg:w-5/12 relative shadow-2xl uk-animation-slide-bottom-small">

        <div class="text-center py-3 border-b">
          <h3 class="text-lg font-semibold">Lịch sử sửa lời</h3>
          <button class="uk-modal-close-default bg-gray-100 rounded-full p-2.5 right-2" type="button" uk-close uk-tooltip="title: Close ; pos: bottom ;offset:7"></button>
        </div>

        <div class="p-4" v-if="updateLyrics.length" style="height: calc(100vh - 200px); overflow-y:scroll">
          <div class="card p-4 mb-5 text-gray-700 bg-gray-100 dark:bg-gray-800 dark:text-gray-100" v-for="lyric in updateLyrics" :key="'lyric' + lyric.id" :id="'lyric' + lyric.id">
            <p><u><router-link class="font-semibold" :to="{name: 'UserDetail', params: {id: lyric.user.id}}" :title="lyric.user.username">{{ lyric.user.username }}</router-link>: <span class="italic text-sm font-normal" :title="lyric.created_at">{{ timeago(lyric.created_at) }}</span></u></p>
            <div class="mt-1" v-html="lyric.diff"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HtmlDiff from 'htmldiff-js';
import ApiService from "../../core/services/api.service";
import {getUser} from "../../core/services/jwt.service";
import PeopleOfSong from "./PeopleOfSong";
import UserOfSong from "./UserOfSong";
import StatisticListen from "../statistic/StatisticListen";
import LoginAuthenticate from "../authenticate/Login.vue";
import {
  canManageSong, canManageSheet, canManageThumbnail, canMarkHq, isAdmin,
  canUpdateLyric, canUpdateThumbnail,
  changePageTitle, changeMetaTag, convertDate, convertLineBreak, getDocumentTitle,
  findImageUrlsFromString, getEditRouterFromSongPrefix, getImplodeString,
  getPluralFromSongPrefix, getSongFieldsByType, isOwner, logActivity, makePlayTime,
  numberFormat, thousandFormat, getUserLoves, hasLoved, isLoggedIn,
  timeago
} from "../../core/services/utils.service";
import SongItem from "../SongItem";
import ShowMore from "../ShowMore";
import LatestSongBox from "./LatestSongBox";
import Comment from "../comment/Comment";
import FcatOfSong from "./FcatOfSong";
import MelodyOfSong from "./MelodyOfSong";
import NextPrev from "../NextPrev";
import Right from "../right/Right";
import AddToPlaylist from "../playlist/AddToPlaylist";
import Player from "../Player";
import RelatedDocumentBox from "../document/RelatedDocumentBox";
import LatestComment from "../comment/LatestComment";
import Editor from "../Editor";
import UsersOfSong from "./UserOfSong";
import UpdateImageModal from "../UpdateImageModal";
import UpdateSongYearModal from "../UpdateSongYearModal";
import UpdateTagModal from "../UpdateTagModal";
import ReplaceHistoryModal from "./ReplaceHistoryModal.vue";
import Tag from "../Tag";
import NotFound from "../404";
import UIkit from 'uikit';
import StickyPeople from "../people/StickyPeople.vue";

export default {
  name: 'SongDetail',
  props: {
    user: Object
  },
  components: {
    UpdateImageModal,
    UpdateSongYearModal,
    UpdateTagModal,
    ReplaceHistoryModal,
    UsersOfSong,
    LatestComment,
    LatestSongBox,
    RelatedDocumentBox,
    Player,
    AddToPlaylist,
    NextPrev,
    MelodyOfSong,
    FcatOfSong,
    ShowMore,
    SongItem,
    Comment,
    UserOfSong,
    Right,
    Editor,
    Tag,
    NotFound,
    StatisticListen,
    LoginAuthenticate,
    StickyPeople,
    PeopleOfSong
  },
  data() {
    return {
      id: null,
      plural: null,
      editRouter: null,
      commentId: null,
      type: "audio",
      prefix: null,
      playTime: null,
      playlistTitle: "",
      shareCount: 0,
      title: "",
      url: "",
      image: "https://static.bcdcnt.net/assets/images/bcdcnt-bg.png",
      audio: "",
      video: "",
      newContent: "",
      isEditing: false,
      disableLyricBtn: false,
      hasLoved: false,
      song: {
        id: 0,
        title: "",
        subtitle: "",
        slug: "",
        content: "",
        views: 0,
        downloads: 0,
        likes: 0,
        empty_file: 0,
        file_type: "audio",
        thumbnail: null,
        file: {
          id: "",
          is_hq: 0,
          audio_url: "",
          video_url: ""
        },
        sheet: {
          id: "",
          year: ""
        },
        is_hq: false
      },
      playerOptions: {
        autoplay: true,
        loop: true,
        controls: true,
        poster: "",
        sources: [
          {
            src: "",
            type: "video/mp4"
          }
        ]
      },
      sameSongs: {
        title: "",
        slug: "",
        data: [],
        paginatorInfo: {
          currentPage: 1,
          lastPage: 0,
          total: 0
        }
      },
      sameSongTitle: "",
      sameSongSlug: "",
      sameSongsSong: {
        title: "",
        slug: "",
        data: [],
        paginatorInfo: {
          currentPage: 1,
          lastPage: 0,
          total: 0
        }
      },
      sameSongsKaraoke: {
        title: "",
        slug: "",
        data: [],
        paginatorInfo: {
          currentPage: 1,
          lastPage: 0,
          total: 0
        }
      },
      sameSongsInstrumental: {
        title: "",
        slug: "",
        data: [],
        paginatorInfo: {
          currentPage: 1,
          lastPage: 0,
          total: 0
        }
      },
      suggestSongs: [],
      sheets: [],
      artistSongs: {},
      poetSongs: {},
      userSongs: {},
      composerSongs: {},
      addToPlaylistKey: "",
      updateLyricUsers: [],
      updateLyricUserIds: [],
      updateLyrics: []
    }
  },
  methods: {
    resetData() {
      this.sameSongs = {
        data: [],
        paginatorInfo: {
          currentPage: 1,
          lastPage: 0,
          total: 0
        }
      };
      this.sameSongsSong = {
        data: [],
        paginatorInfo: {
          currentPage: 1,
          lastPage: 0,
          total: 0
        }
      };
      this.sameSongsUser = {
        data: [],
        paginatorInfo: {
          currentPage: 1,
          lastPage: 0,
          total: 0
        }
      };
      this.sameSongsInstrumental = {
        data: [],
        paginatorInfo: {
          currentPage: 1,
          lastPage: 0,
          total: 0
        }
      };
      this.artistSongs = {};
      this.poetSongs = {};
      this.composerSongs = {};
      this.userSongs = {};
      this.sheets = [];
      this.sheetImages = [];
      this.updateLyricUsers = [];
      this.updateLyricUserIds = [];
    },
    initData() {
      this.suggestSongs = [];
    },
    loadSong() {
      let plural = this.plural;
      let fields = this.getFields();

      this.resetData();

      let query = `query($id: ID!) {
        ${plural}(id: $id) {
          id
          title
          subtitle
          slug
          content
          views
          downloads
          likes
          file_type
          status
          year
          record_year
          allow_comment
          empty_file
          created_at
          uploads(orderBy: [{column: "id", order: DESC}], where: {AND: [{column: "status", value: "approved"}]}) {
            id
            file {
              id
              audio_url
              created_at
              is_hq
              user {
                id
                username
                avatar {
                  url
                }
              }
            }
          }
          uploader {
            id
            username
            avatar {
              url
            }
          }
          ${fields}
          thumbnail {
            url
            user {
              id
              username
              avatar {
                url
              }
            }
          }
          file {
            id
            video_url
            audio_url
            is_hq
            bitrate
            user {
              id
              username
              avatar {
                url
              }
            }
          }
          loves(first: 1000) {
            data {
              user_id
              user {
                id
                username
              }
            }
          }
          sheet {
            id
            title
            slug
            year
            lyric_type
            content
            composer_alias
            revisions(first: 100) {
              data {
                id
                content
                uploader {
                  id
                  username
                }
              }
            }
            uploader {
              id
              username
            }
            composers(first: 20, orderBy: [{column: "sheet_composer.order", order: ASC}]) {
              data {
                id
                title
                slug
              }
            }
            tags {
              id
              name
              slug
            }
          }
          documents(first: 10) {
            data {
              id
              title
              slug
              thumbnail {
                url
              }
            }
          }
        }
      }`;

      ApiService.graphql(query, {id: this.id})
          .then(({data}) => {
            if (data.data && data.data[plural]) {
              if (data.data[plural].status !== 1) {
                this.song = null;
                return;
              }

              this.song = data.data[plural];
              this.newContent = this.song.content;
              this.song.content = makePlayTime(convertLineBreak(this.song.content));
              if (this.type === "audio") {
                this.$emit("playPlaylist", this.song, "song", false, this.prefix, this.playTime);
              }

              if (this.song.file_type === "video" && this.type === "video") {
                this.playerOptions.sources[0].src = this.song.file.video_url;
                logActivity("listen", plural, this.song.id);
              }

              this.sheetImages = [];

              if (this.song.sheet) {
                if (this.song.sheet.content) {
                  let images = findImageUrlsFromString(this.song.sheet.content);
                  if (images.length) {
                    this.addSheetImages(images, this.song.sheet.uploader);
                  }
                }

                if (this.song.sheet.revisions && this.song.sheet.revisions.data && this.song.sheet.revisions.data.length) {
                  for (var i = 0; i < this.song.sheet.revisions.data.length; i++) {
                    let images = findImageUrlsFromString(this.song.sheet.revisions.data[i].content);
                    if (images.length) {
                      this.addSheetImages(images, this.song.sheet.revisions.data[i].uploader);
                    }
                  }
                }
              }

              if (this.prefix !== "tieng-tho" && this.song.sheet && this.song.sheet.composers && this.song.sheet.composers.data && this.song.sheet.composers.data.length) {
                this.sameSongTitle = this.song.title;
                this.sameSongSlug = this.song.sheet.composers.data[0].slug;

                this.song.sheet.composers.data.map((composer) => {
                  this.composerSongs[composer.id] = {
                    title: composer.title,
                    slug: composer.slug,
                    data: [],
                    paginatorInfo: {
                      currentPage: 1,
                      lastPage: 0,
                      total: 0
                    }
                  }
                  this.loadComposerSongs(composer.id);
                });

                this.loadSameSongs();

                if (this.prefix === "bai-hat") {
                  this.loadSameSongs('karaoke');
                  this.loadSameSongs('instrumental');
                } else if (this.prefix === "khi-nhac") {
                  this.loadSameSongs('song');
                  this.loadSameSongs('karaoke');
                } else if (this.prefix === "thanh-vien-hat") {
                  this.loadSameSongs('song');
                  this.loadSameSongs('instrumental');
                }
              }

              if (this.song.sheet && this.song.sheet.poets && this.song.sheet.poets.data && this.song.sheet.poets.data.length) {
                this.song.sheet.poets.data.map((poet) => {
                  this.poetSongs[poet.id] = {
                    title: poet.title,
                    slug: poet.slug,
                    data: [],
                    paginatorInfo: {
                      currentPage: 1,
                      lastPage: 0,
                      total: 0
                    }
                  }
                  this.loadPoetSongs(poet.id);
                });
              }

              if (this.song.artists && this.song.artists.data && this.song.artists.data.length) {
                this.song.artists.data.map((artist) => {
                  this.artistSongs[artist.id] = {
                    title: artist.title,
                    slug: artist.slug,
                    data: [],
                    paginatorInfo: {
                      currentPage: 1,
                      lastPage: 0,
                      total: 0
                    }
                  }
                  this.loadArtistSongs(artist.id);
                });
              }

              if (this.song.poets && this.song.poets.data && this.song.poets.data.length) {
                this.song.poets.data.map((poet) => {
                  this.poetSongs[poet.id] = {
                    title: poet.title,
                    slug: poet.slug,
                    data: [],
                    paginatorInfo: {
                      currentPage: 1,
                      lastPage: 0,
                      total: 0
                    }
                  }
                  this.loadPoetSongs(poet.id);
                });
              }

              if (this.song.users && this.song.users.data && this.song.users.data.length) {
                this.song.users.data.map((user) => {
                  this.userSongs[user.id] = {
                    title: user.username,
                    data: [],
                    paginatorInfo: {
                      currentPage: 1,
                      lastPage: 0,
                      total: 0
                    }
                  }
                  this.loadUserSongs(user.id);
                });
              }

              this.loadUpdateLyricUsers();
              this.checkHasLoved();
              this.title = getDocumentTitle(this.song);
              this.url = window.location.href;
              this.image = this.song.thumbnail ? this.song.thumbnail.url : "https://static.bcdcnt.net/assets/images/bcdcnt-bg.png";
              this.audio = this.song.file && this.song.file.audio_url ? this.song.file.audio_url : "";
              this.video = this.song.file && this.song.file.video_url ? this.song.file.video_url : "";
              changePageTitle(this.title);
              changeMetaTag("description", this.song.content);
              changeMetaTag("title", this.title);
              changeMetaTag("image", this.image);
              changeMetaTag("url", this.url);
              changeMetaTag("audio", this.audio);
              changeMetaTag("video", this.video);
            } else {
              this.song = null;
            }
          })
          .catch((response) => {
            console.log(response);
          });
    },
    addSheetImages(images, uploader) {
      images.forEach((url, index) => {
        var credit = uploader ?  " bởi " + uploader.username : "";
        let sheetImage = {
          source: url,
          caption: (index+1) + "/" + images.length + credit,
          sheet: this.song.sheet
        };
        this.sheetImages.push(sheetImage);
      });
    },
    loadSameSongs(type) {
      let plural = this.plural;
      let field = type ? type + "s" : getPluralFromSongPrefix(this.prefix) + "s";
      let fields = this.getFields(type);

      let query = `query($id: ID!, $page: Int) {
        ${plural}(id: $id) {
          sheet {
            ${field}(first: 3, page: $page, orderBy: [{column: "views", order: DESC}]) {
              data {
                id
                slug
                title
                subtitle
                views
                thumbnail {
                  url
                }
                ${fields}
              }
              paginatorInfo {
                currentPage
                lastPage
                total
              }
            }
          }
        }
      }`;

      var key = "";
      switch (type) {
        case "song":
          key = "sameSongsSong";
          break;
        case "instrumental":
          key = "sameSongsInstrumental";
          break;
        case "karaoke":
          key = "sameSongsKaraoke";
          break;
        default:
          key = "sameSongs";
          break;
      }

      ApiService.graphql(query, {id: this.song.id, page: this[key].paginatorInfo.currentPage})
          .then(({data}) => {
            if (data.data && data.data[plural] && data.data[plural].sheet && data.data[plural].sheet[field] && data.data[plural].sheet[field].data) {
              this[key] = data.data[plural].sheet[field];
            }
          })
          .catch((response) => {
            console.log(response);
          });
    },
    openSheetLightbox(index) {
      UIkit.lightboxPanel({items: this.sheetImages}).show(index);
    },
    loadComposerSongs(id) {
      let field = getPluralFromSongPrefix(this.prefix) + "s";
      let fields = this.getFields();

      let query = `query($slug: String!, $page: Int) {
        composer(slug: $slug) {
          ${field}(first: 3, page: $page, orderBy: [{column: "title", order: ASC}]) {
            data {
              id
              slug
              title
              subtitle
              views
              thumbnail {
                url
              }
               ${fields}
            },
            paginatorInfo {
              currentPage
              lastPage
              total
            }
          }
        }
      }`;

      ApiService.graphql(query, {
        slug: this.composerSongs[id].slug,
        page: this.composerSongs[id].paginatorInfo.currentPage
      })
          .then(({data}) => {
            if (data.data && data.data.composer && data.data.composer[field]) {
              this.composerSongs[id].data = data.data.composer[field].data;
              this.composerSongs[id].paginatorInfo = data.data.composer[field].paginatorInfo;
            }
          })
          .catch((response) => {
            console.log(response);
          });
    },
    loadArtistSongs(id) {
      let field = getPluralFromSongPrefix(this.prefix) + "s";
      let fields = this.getFields(null, "artist");

      let query = `query($slug: String!, $page: Int) {
        artist(slug: $slug) {
          ${field}(first: 3, page: $page, orderBy: [{column: "title", order: ASC}]) {
            data {
              id
              slug
              title
              subtitle
              views
              thumbnail {
                url
              }
              ${fields}
            },
            paginatorInfo {
              currentPage
              lastPage
              total
            }
          }
        }
      }`;

      ApiService.graphql(query, {slug: this.artistSongs[id].slug, page: this.artistSongs[id].paginatorInfo.currentPage})
          .then(({data}) => {
            if (data.data && data.data.artist && data.data.artist[field]) {
              this.artistSongs[id].data = data.data.artist[field].data;
              this.artistSongs[id].paginatorInfo = data.data.artist[field].paginatorInfo;
            }
          })
          .catch((response) => {
            console.log(this.artistSongs, id);
            console.log(response);
          });
    },
    loadPoetSongs(id) {
      let field = getPluralFromSongPrefix(this.prefix) + "s";
      let fields = this.getFields();

      let query = `query($slug: String!, $page: Int) {
        poet(slug: $slug) {
          ${field}(first: 3, page: $page, orderBy: [{column: "title", order: ASC}]) {
            data {
              id
              slug
              title
              subtitle
              views
              thumbnail {
                url
              }
              ${fields}
            },
            paginatorInfo {
              currentPage
              lastPage
              total
            }
          }
        }
      }`;

      ApiService.graphql(query, {slug: this.poetSongs[id].slug, page: this.poetSongs[id].paginatorInfo.currentPage})
          .then(({data}) => {
            if (data.data && data.data.poet && data.data.poet[field]) {
              this.poetSongs[id].data = data.data.poet[field].data;
              this.poetSongs[id].paginatorInfo = data.data.poet[field].paginatorInfo;
            }
          })
          .catch((response) => {
            console.log(response);
          });
    },
    loadUserSongs(id) {
      let query = `query($id: ID!, $page: Int) {
        user(id: $id) {
          karaokes(first: 3, page: $page, orderBy: [{column: "title", order: ASC}]) {
            data {
              id
              slug
              title
              subtitle
              views
              thumbnail {
                url
              }
              sheet {
                composers(first: 20, orderBy: [{column: "sheet_composer.order", order: ASC}]) {
                  data {
                    id
                    title
                    slug
                  }
                }
              }
            },
            paginatorInfo {
              currentPage
              lastPage
              total
            }
          }
        }
      }`;

      ApiService.graphql(query, {id: id, page: this.userSongs[id].paginatorInfo.currentPage})
          .then(({data}) => {
            if (data.data && data.data.user && data.data.user.karaokes) {
              this.userSongs[id].data = data.data.user.karaokes.data;
              this.userSongs[id].paginatorInfo = data.data.user.karaokes.paginatorInfo;
            }
          })
          .catch((response) => {
            console.log(response);
          });
    },
    updateLyric() {
      this.disableLyricBtn = true;

      let query = `mutation($id: ID!, $type: String!, $content: String!) {
        updateLyric(id: $id, type: $type, content: $content)
      }`;

      ApiService.graphql(query, {id: this.id, type: this.objectType, content: this.newContent})
          .then(({data}) => {
            if (data.data && data.data.updateLyric) {
              this.loadSong();
              this.isEditing = false;
              this.disableLyricBtn = false;
            } else {
              this.$toast.error(data.errors[0].message);
              this.disableLyricBtn = false;
            }
          })
          .catch((response) => {
            console.log(response);
            this.disableLyricBtn = false;
          });
    },
    loadUpdateLyricUsers() {
      let query = `query($object_id: Mixed, $object_type: Mixed) {
        activities(first: 100, orderBy: [{column: "id", order: DESC}], where: {AND: [{column: "action", value: "update_lyric"}, {column: "object_type", value: $object_type}, {column: "object_id", value: $object_id}]}) {
          edges {
            node {
              id
              extra
              created_at
              user {
                id
                username
                avatar {
                  url
                }
              }
            }
            cursor
          }
        }
      }
      `;

      this.updateLyricUsers = [];
      this.updateLyricUserIds = [];
      this.updateLyrics = [];

      ApiService.graphql(query, {object_id: this.song.id, object_type: this.objectType})
          .then(({data}) => {
            if (data.data && data.data.activities && data.data.activities.edges && data.data.activities.edges.length) {
              data.data.activities.edges.map((item) => {
                let lyric = item.node;
                lyric.extra = JSON.parse(lyric.extra);
                lyric.diff = HtmlDiff.execute(lyric.extra.old_value, lyric.extra.new_value);
                this.updateLyrics.push(lyric);
                
                if (!this.updateLyricUserIds.includes(item.node.user.id)) {
                  this.updateLyricUsers.push(item.node.user);
                  this.updateLyricUserIds.push(item.node.user.id);
                }
              });
            }
          })
          .catch((response) => {
            console.log(response);
          });
    },
    markOrUnmarkAsHQ() {
      if (this.song.file.is_hq === 1) {
        this.unmarkHQ();
      } else {
        this.markAsHQ();
      }
    },
    markAsHQ() {
      let query = `mutation($file_id: ID!) {
        markAsHQ(file_id: $file_id) {
          id
        }
      }
      `;

      ApiService.graphql(query, {file_id: this.song.file.id})
          .then(({data}) => {
            if (data.data && data.data.markAsHQ) {
              this.$toast.success("Đánh dấu bản thu VOV chất lượng cao thành công");
              this.song.file.is_hq = 1;
            } else {
              this.$toast.error(data.errors[0].message);
              this.song.file.is_hq = 0;
            }
          })
          .catch((response) => {
            this.$toast.error(response.message);
            this.people.is_sticky = 0;
          });
    },
    unmarkHQ() {
      let query = `mutation($file_id: ID!) {
        unmarkHQ(file_id: $file_id) {
          id
        }
      }
      `;

      ApiService.graphql(query, {file_id: this.song.file.id})
          .then(({data}) => {
            if (data.data && data.data.unmarkHQ) {
              this.$toast.success("Bỏ đánh dấu bản thu VOV chất lượng cao thành công");
              this.song.file.is_hq = 0;
            } else {
              this.$toast.error(data.errors[0].message);
              this.song.file.is_hq = 1;
            }
          })
          .catch((response) => {
            this.$toast.error(response.message);
            this.song.file.is_hq = 1;
          });
    },
    reRender() {
      if (this.$route.query.t) {
        this.playTime = this.$route.query.t;
      }
      this.loadSong();
      this.getShareCount();
    },
    numberFormat(x) {
      return numberFormat(x);
    },
    thousandFormat(x) {
      return thousandFormat(x);
    },
    timeago(x) {
      return timeago(x);
    },
    getFields(type, from) {
      if (!type) {
        type = getPluralFromSongPrefix(this.prefix);
      }
      return getSongFieldsByType(type, from);
    },
    getShareCount() {
      window.$('.share-count').html('<div class="fb-share-button" data-href="' + this.url + '" data-layout="button_count" data-size="large"><a target="_blank" href="https://www.facebook.com/sharer/sharer.php?u=' + this.url + '&amp;src=sdkpreparse" class="fb-xfbml-parse-ignore">Chia sẻ</a></div>');
    },
    isLoggedIn() {
      return isLoggedIn();
    },
    downloadSong() {
      logActivity("download", this.objectType, this.id);

      let composers = "";
      let artists = "";

      if (this.objectType === "poem" && this.song.poets && this.song.poets.data) {
        composers = getImplodeString(this.song.poets.data, "title");
      } else if (this.objectType === "folk" && this.song.recomposers && this.song.recomposers.data) {
        composers = getImplodeString(this.song.recomposers.data, "title");
      } else if (this.song.sheet && this.song.sheet.composers && this.song.sheet.composers.data) {
        composers = getImplodeString(this.song.sheet.composers.data, "title");
      }

      if (this.objectType === "karaoke" && this.song.users && this.song.users.data) {
        artists = getImplodeString(this.song.users.data, "username");
      } else if (this.song.artists && this.song.artists.data) {
        artists = getImplodeString(this.song.artists.data, "title");
      }

      let data = {
        link: this.song.file[this.song.file_type + '_url'],
        composers: composers,
        artists: artists,
        title: this.song.title,
        subtitle: this.song.subtitle
      };

      let token = window.btoa(encodeURIComponent(JSON.stringify(data)));
      let url = process.env.VUE_APP_MP3_API_URL + "/download?token=" + token;
      window.open(url, '_blank').focus();
    },
    convertDate(x) {
      return convertDate(x);
    },
    isOwner(user_id) {
      return isOwner(user_id);
    },
    getUser() {
      return getUser();
    },
    focusOnEditor() {
      this.isEditing = true;
      
      setTimeout(function() {
        window.$("#lyric .ck-editor__editable").focus();
      }, 100);
    },
    handleUpdateSheetTagDone(tagIds) {
      let query = `mutation($sheet_id: ID!, $tag_ids: [ID]) {
        updateSheetTag(sheet_id: $sheet_id, tag_ids: $tag_ids) {
          id
        }
      }`;

      ApiService.graphql(query, { sheet_id: this.song.sheet.id, tag_ids: tagIds })
        .then(({ data }) => {
          if (data.data && data.data.updateSheetTag) {
            this.$toast.success("Cập nhật thành công");
            this.loadSong();
          } else {
            this.$toast.error(data.errors[0].message);
          }
        })
        .catch((response) => {
          this.$toast.error(response.message);
        });
    },
    loveOrUnlove() {
      if (this.hasLoved) {
        this.unlove();
      } else {
        this.love();
      }
    },
    love() {
      let query = `mutation($lovable_id: ID!, $lovable_type: String!) {
        love(lovable_id: $lovable_id, lovable_type: $lovable_type)
      }`;
      let type = getPluralFromSongPrefix(this.prefix);

      ApiService.graphql(query, { lovable_id: this.id, lovable_type: type })
        .then(({ data }) => {
          if (data.data && data.data.love !== null) {
            this.hasLoved = true;
            this.song.likes = data.data.love;
          } else {
            if (data.errors[0].message == "Unauthenticated.") {
              window.$("#loginModal").addClass("uk-open");
            } else {
              this.$toast.error(data.errors[0].message);
            }
          }
        })
        .catch((response) => {
          this.$toast.error(response.message);
        });
    },
    unlove() {
      let query = `mutation($lovable_id: ID!, $lovable_type: String!) {
        unlove(lovable_id: $lovable_id, lovable_type: $lovable_type)
      }`;
      let type = getPluralFromSongPrefix(this.prefix);

      ApiService.graphql(query, { lovable_id: this.id, lovable_type: type })
        .then(({ data }) => {
          if (data.data && typeof data.data.unlove !== 'undefined') {
            this.hasLoved = false;
            this.song.likes = data.data.unlove;
          } else {
            if (data.errors[0].message == "Unauthenticated.") {
              window.$("#loginModal").addClass("uk-open");
            } else {
              this.$toast.error(data.errors[0].message);
            }
          }
        })
        .catch((response) => {
          this.$toast.error(response.message);
        });
    },
    getUserLoves() {
      return getUserLoves(this.song);
    },
    checkHasLoved() {
      this.hasLoved = hasLoved(this.song, this.user);
    },
    handleCreateSheetFromCommentDone() {
      this.reRender();
    },
    handleReplaceFileFromCommentDone() {
      this.reRender();
    },
    handleReplaceImageFromCommentDone() {
      this.reRender();
    },
  },
  mounted() {
    this.id = this.$route.params.id;
    this.prefix = this.$route.params.prefix;
    this.plural = getPluralFromSongPrefix(this.prefix);
    this.editRouter = getEditRouterFromSongPrefix(this.prefix);
    this.commentId = this.$route.query.comment_id;
    this.type = this.$route.query.type || "audio";
    this.url = "https://bcdcnt.net" + this.$route.path;
    this.reRender();
  },
  beforeRouteUpdate(to, from, next) {
    this.id = to.params.id;
    this.prefix = to.params.prefix;
    this.plural = getPluralFromSongPrefix(this.prefix);
    this.editRouter = getEditRouterFromSongPrefix(this.prefix);
    this.commentId = to.query.comment_id;
    this.type = to.query.type || "audio";
    this.url = "https://bcdcnt.net" + to.path;
    this.reRender();
    window.scrollTo(0, 0);
    next();
  },
  created() {
    this.initData();
    window.addEventListener("message", (event) => {
      if (event.data.action === "suggestSongs" && event.data.value.id === this.id && event.data.value.type === this.plural) {
        this.suggestSongs = event.data.value.data;
      }
    }, false);
  },
  watch: {
    "song.file.is_hq": {
      handler(newVal) {
        this.song.is_hq = newVal == 1;
      },
      deep: true
    },
  },
  computed: {
    objectType() {
      return getPluralFromSongPrefix(this.prefix);
    },
    canManageSong() {
      return canManageSong();
    },
    canUpdateLyric() {
      return canUpdateLyric();
    },
    canUpdateThumbnail() {
      return canUpdateThumbnail();
    },
    canManageSheet() {
      return canManageSheet();
    },
    canManageThumbnail() {
      return canManageThumbnail();
    },
    canMarkHq() {
      return canMarkHq();
    },
    isAdmin() {
      return isAdmin();
    },
    inTopMostListen() {
      let me = getUser();

      if (!me) {
        return false;
      }

      for (var i=  0; i < this.mostListens.length; i++) {
        if (this.mostListens[i].user.id == me.id) {
          return true;
        }
      }

      return false;
    }
  }
}
</script>
